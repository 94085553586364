import Tictactoe from "../assets/projects/tictactoe.jpg";
import mausam from "../assets/projects/mausam.jpg";
import crypker from "../assets/projects/crypker.jpg";
import textmanipulator from "../assets/projects/textmanipulator.jpg";
import AIRLINE from "../assets/projects/AIRLINE.jpg";
import robosoccer from "../assets/projects/robosoccer.jpg";
import smartaccesssystem from "../assets/projects/smartaccesssystem.png";
import linerobo from "../assets/projects/linerobo.jpg";
import roboarm from "../assets/projects/roboarm.jpg";
import participantBadge1 from "../assets/projects/Graphic Designs/Badges/1.png";
import sticker1 from "../assets/projects/Graphic Designs/Badges/7.png";
import sticker2 from "../assets/projects/Graphic Designs/Badges/8.png";
import smartHome from "../assets/projects/Graphic Designs/application/1.png";
import SajiloAmbulance from "../assets/projects/Graphic Designs/application/3.png";
import dashain from "../assets/projects/Graphic Designs/festival/Dashain.jpg";
import shivaratri from "../assets/projects/Graphic Designs/festival/MahaShivaRatri.png";
import newyear from "../assets/projects/Graphic Designs/festival/Newyear.jpg";
import tihar from "../assets/projects/Graphic Designs/festival/tihar.jpg";
import projectDemo from "../assets/projects/Graphic Designs/banners/battle.png";
import battleforspeed from "../assets/projects/Graphic Designs/banners/battleforspeed.jpg";
import roborookies from "../assets/projects/Graphic Designs/banners/rookies.png";
import deltaArm from "../assets/projects/deltaArm.jpg";

export const projects = {
  webData: [
    {
      id: 1,
      name: "Weather Forecast",
      des: "React, HTML, SASS",
      image: mausam,
      github: "https://github.com/sh7vashrestha/Mausam",
      live: "https://sh7vashrestha.github.io/Mausam/",
    },
    {
      id: 2,
      name: "Cryptocurrency Tracker",
      des: "React, HTML, SASS",
      image: crypker,
      github: "https://github.com/sh7vashrestha/Crypker",
      live: "https://sh7vashrestha.github.io/Crypker/",
    },
    {
      id: 3,
      name: "Airline Management System",
      des: "NodeJS, HTML, CSS, MySQL",
      image: AIRLINE,
      github: "https://github.com/sh7vashrestha/AirlineManagementSystem",
      live: "",
    },
    {
      id: 4,
      name: "Text Manipulator",
      des: "ReactJS, HTML, CSS",
      image: textmanipulator,
      github:
        "https://github.com/sh7vashrestha/Text-Manipulator/settings/pages",
      live: "https://sh7vashrestha.github.io/Text-Manipulator/",
    },
    {
      id: 5,
      name: "TIC-TAC-TOE",
      des: "HTML, CSS, Vanilla JS",
      image: Tictactoe,
      github: "https://github.com/sh7vashrestha/TICTACTOE",
      live: "https://sh7vashrestha.github.io/TICTACTOE/",
    },
  ],
  hardwareData: [
    {
      id: 1,
      name: "Robotic Arm",
      des: "Arduino, OpenCV",
      image: roboarm,
      github: "https://github.com/WRCRoboticsClub/robotics-arm",
      live: "",
    },
    {
      id: 2,
      name: "RoboSoccer",
      des: "Arduino",
      image: robosoccer,
      github: "https://github.com/sh7vashrestha/ROBOSOCCER",
      live: "",
    },
    {
      id: 3,
      name: "Line-Following-Bot",
      des: "Arduino",
      image: linerobo,
      github: "https://github.com/sh7vashrestha/LineFollowingRobot",
      live: "",
    },
    {
      id: 4,
      name: "Smart Door Access System",
      des: "Arduino, Flutter",
      image: smartaccesssystem,
      github: "https://github.com/sh7vashrestha/SmartAccessSystem",
      live: "",
    },
    {
      id: 5,
      name: "Automated Delta Waste Classifier",
      des: "Arduino, DeltaKinematics, YOLO",
      image: deltaArm,
      github: "https://github.com/sh7vashrestha/DeltaArm",
      live: "",
    },
  ],
  graphicData: [
    {
      id: 1,
      name: "Dashain Banner",
      des: "Banner Created for Dahain festival",
      image: dashain,
    },
    {
      id: 2,
      name: "Tihar Banner",
      des: "Banner Created for tihar festival",
      image: tihar,
    },
    {
      id: 3,
      name: "New Year",
      des: "Banner Created for new year 2080",
      image: newyear,
    },
    {
      id: 4,
      name: "Shivaratri Banner",
      des: "Banner Created for Maha-ShivaRatri festival",
      image: shivaratri,
    },
    {
      id: 5,
      name: "Battle For Speed",
      des: "Battle For Speed 2022",
      image: battleforspeed,
    },
    {
      id: 6,
      name: "Project Demonstration",
      des: "Banner for project demo 2022",
      image: projectDemo,
    },
    {
      id: 7,
      name: "Roborokiees",
      des: "Banner for roborookie",
      image: roborookies,
    },
    {
      id: 8,
      name: "Participation Badge",
      des: "Badge for participation in roborookie",
      image: participantBadge1,
    },
    {
      id: 9,
      name: "Stickers",
      des: "Sticker for rookies",
      image: sticker1,
    },
    {
      id: 10,
      name: "Stickers",
      des: "Sticker for rookies",
      image: sticker2,
    },
    {
      id: 11,
      name: "Sajilo Ambulance Home",
      des: "Sajilo Ambulance UI",
      image: SajiloAmbulance,
    },
    {
      id: 12,
      name: "Smart Home Control",
      des: "Smart Home UI",
      image: smartHome,
    },
  ],
};

export const blogData = [
  {
    id: 1,
    title: "Apple: The Trendsetter.",
    description:
      "How Apple is setting the path and other companies are following Apple's footsteps",
    link: "https://forefronttechno.wordpress.com/2020/07/02/apple-the-trend-setter/",
  },
  {
    id: 2,
    title:
      "Google Pixel: State of the art software meets the mediocre hardware.",
    description:
      "Google's problem of odinary hardware combined with extraodinary software.",
    link: "https://forefronttechno.wordpress.com/2020/07/01/google-pixel-state-of-the-art-software-meets-the-mediocre-hardware/",
  },
  {
    id: 3,
    title: "Tesla: Future of world’s Transportation?",
    description: "Rise of tesla and its impact in the world and its economy.",
    link: "https://forefronttechno.wordpress.com/2020/05/11/tesla-future-of-worlds-transportation/",
  },
  {
    id: 4,
    title: "Rise of Zoom",
    description: "Rise of Zoom and its impact during COVID times.",
    link: "https://forefronttechno.wordpress.com/2020/05/10/rise-of-zoom/",
  },
  {
    id: 5,
    title: "Future of smartphone",
    description:
      "Characteristics of smartphones that will be developed in future.",
    link: "https://forefronttechno.wordpress.com/2020/05/10/future-of-smartphones/",
  },
];

export const quotes = [
  "When you let fear control your life, you end up at a place that's worse than you were trying to avoid.",
  "We go too far in thinking but forget that destiny is written.",
  "A voice, not an echo.",
  "A man of wisdom delights in water.",
  "Not all those who wander are lost.",
];
